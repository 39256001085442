import partners from "../assets/alliance.jpg";
import img1 from "../assets/img1.webp";
import img2 from "../assets/img2.webp";
import img3 from "../assets/img3.webp";

const MissionSection = () => {
  return (
    <>
      <div class="mr-8 sm:m-20 md:mt-10 grid grid-cols-1 md:grid-cols-2 gap-4">
        <div class=" mission-section-wrapper col-span-1  p-4">
          <h3 id="mission-title">About Us</h3>
          <p>
            First Monument was formed in 1866 through the merger of two separate
            banks. These banks had capitalised on the expansion of trade between
            Europe, Asia and Africa. First Monument traditional trade was in
            cotton from Mumbai, indigo and tea from Kolkata, rice from Burma,
            sugar from Java, tobacco from Sumatra, hemp from Manila and silk
            from Yokohama. The bank played a major role in the development of
            trade with the East following the opening of the Suez Canal in 1869
            and the extension of the telegraph to China in 1871. First Monument
            was founded in London in 1862 from the Cape Colony in South Africa,
            and started business in Port Elizabeth in the following year. The
            bank was prominent in financing the development of the diamond
            fields of Kimberley from the 1870s. It later extended its network
            further north to the new town of Johannesburg when gold was
            discovered there in 1886. The bank expanded in Southern, Central and
            Eastern Africa and had 600 offices by 1953.
          </p>
        </div>

        <div class="col-span-1  p-4  no-top">
          <div class="grid grid-cols-1 mb-4 gap-4">
            <div class="col-span-1  p-4">
              <img src={img3} alt="img" className="rounded-xl" />
            </div>
          </div>

          <div class="grid grid-cols-2 gap-4">
            <div class="col-span-1  p-4">
              <img
                src={img2}
                alt="img"
                className="rounded-xl"
                id="top-sm-img"
              />
            </div>
            <div class="col-span-1  p-4">
              <img
                src={img1}
                alt="img"
                className="rounded-xl"
                id="bottom-sm-img"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="partners p-4">
        <p style={{ color: "#216206", fontSize: "20px" }}>
          First Monument is proud to be a member of these organizations:
        </p>
        <img src={partners} alt="partners" />
      </div>
    </>
  );
};

export default MissionSection;
