import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

const useUserStore = create(
  devtools(
    persist(
      (set) => ({
        users: [],

        updateUsers: (newUsers) =>
          set((state) => ({
            users: newUsers,
          })),
      }),
      { name: "users" }
    )
  )
);

export default useUserStore;
