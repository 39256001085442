import Template from "../components/Template";
import MobileDepositSection from "../components/MobileDepositSection";

const MobileDeposit = () => {
  return (
    <>
      <Template />
      <MobileDepositSection />
    </>
  );
};

export default MobileDeposit;
