import Template from "../components/Template";
import InternationalSection from "../components/InternationSection";

const InternationalTransfer = () => {
  return (
    <>
      <Template />
      <InternationalSection />
    </>
  );
};

export default InternationalTransfer;
