import Template from "../components/Template";
import PinSection from "../components/PinSection"

const ResetPin = () => {
  return (
    <>
      <Template />
      <PinSection />
    </>
  );
};

export default ResetPin;
