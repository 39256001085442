import globalImg from "../assets/about.jpg";
import Navbar from "../components/Navbar";

const HomeBusiness = () => {
  return (
    <>
      <Navbar />

      <div class="p-4 reg-section">
        <div className="p-4 produce-form-wrapper">
          <div className="produce-form">
            {/* <h2>ABOUT US</h2> */}
            <img src={globalImg} alt="about" />
            <h3 className=" mb-2 mt-8 dashboard-label font-bold">
              Business banking made better
            </h3>
            <p>
              At Firtst Monument, we are committed to responding to your
              business needs quickly and effectively. As part of your business
              community, we are passionate about your success and the growth of
              our community as a whole. We are dedicated to serve you, our
              members, and are uniquely structured to ensure your needs will
              always come first, because your both a customer and an owner of
              Firtst Monument.
            </p>

            <h3 className=" mb-2 mt-8 dashboard-label font-bold">
              Customized business banking for you
            </h3>

            <p>
              Most entrepreneurs agree that tailored banking solutions are
              important. Surveys prove it. We get it – and that makes us
              different. Sure we use ratios and smart fundamentals. Good
              business math is good business math. But we go further – tailoring
              our full-service commercial banking to you, the human in front of
              us. That’s business banking where people still matter, where
              responsiveness counts, where local decision-making is a priority.
              That’s business banking at Engineering Funds.
            </p>

            <h3 className=" mb-2 mt-8 dashboard-label font-bold">
              No one should have to train new bankers every year
            </h3>

            <p>
              Thousands of business owners who bank at Credit Unions say service
              consistency is a meaningful difference – compared to re-educating
              fresh new bankers all too often. Our staff sees serving business
              owners as a career position – not a stepping stone. Real
              relationships are built on personal trust – in good times and
              not-so-good. That’s business banking where people still matter,
              where responsiveness counts, where local decision-making is a
              priority. That’s business banking at Engineering Funds.
            </p>

            <h3 className=" mb-2 mt-8 dashboard-label font-bold">
              Stop waiting for banking answers from higher up and start looking
              the decision-makers in the eye
            </h3>

            <p>
              Ask most business owners – waiting for banking approvals from
              someplace else is a pain. Talk to business owners who bank at your
              Credit Union and the answer is different. We support our local
              experts – we tailor banking solutions to local conditions. It’s a
              good mix – smart people who get to know you in your space with the
              authority to actually help. That’s business banking where people
              still matter, where responsiveness counts, where local
              decision-making is a priority. That’s business banking at
              Engineering Funds.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeBusiness;
