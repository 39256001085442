import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import logo from "../assets/logo.png";
import avatar from "../assets/avatar1.png";
import { Link, NavLink } from "react-router-dom";
import useAuthStore from "../stores/authStore";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

const Template = () => {
  const logout = useAuthStore((state) => state.logout);

  const navigate = useNavigate();
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const [openSection, setOpenSection] = useState(null);

  const handleToggle = (section) => {
    setOpenSection((prevSection) => (prevSection === section ? null : section));
  };

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  return (
    <>
      <nav className="fixed top-0 z-50 w-full bg-white top-nav">
        <div className="px-3 py-3 lg:px-5 lg:pl-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-start rtl:justify-end">
             

              <Link to="/">
                <img src={logo} alt="logo" id="dashboard-logo" />
              </Link>
            </div>
            <div>
              {/* <img src={avatar} alt="logo" id="dashboard-avatar" /> */}

              <button
                onClick={toggleSidebar}
                aria-controls="logo-sidebar"
                type="button"
                className="inline-flex items-center pl-2 pr-4 text-sm text-gray-500 nav-hover  sm:hidden"
              >
                <span className="sr-only">Toggle sidebar</span>

                {isSidebarOpen ? (
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                ) : (
                  <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                )}
              </button>
            </div>
          </div>
        </div>
      </nav>

      {isSidebarOpen ? (
        <div
          className="transparent-div"
          onClick={() => setSidebarOpen(false)}
        ></div>
      ) : (
        ""
      )}

      <aside
        id="logo-sidebar"
        className={`fixed top-0 left-0 z-40 w-64 h-screen pt-20  aside transition-transform ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } bg-white sm:translate-x-0 dark:border-gray-700`}
        aria-label="Sidebar"
      >
        <ul class="space-y-2 pl-0">
          <li>
            <Link to="/dashboard">
              <button
                type="button"
                onClick={() => handleToggle("dashboard")}
                className={`flex items-center side-link  ${
                  openSection === "dashboard" ? "open " : ""
                }`}
                class="flex items-center side-link"
              >
                <span>
                  <li class="bi bi-speedometer2"></li>
                </span>
                <span class="ms-3">Dashboard</span>
              </button>
            </Link>
          </li>

          <li>
            <button
              type="button"
              onClick={() => handleToggle("security")}
              className={`flex items-center side-link  ${
                openSection === "security" ? "open " : ""
              }`}
            >
              <span>
                <i class="bi bi-lock side-icon"></i>
              </span>

              <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">
                Security
              </span>
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 10 6"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 4 4 4-4"
                />
              </svg>
            </button>

            <ul
              className={`subLink-wrapper ${
                openSection === "security" ? "open" : ""
              }`}
            >
              <Link to="/resetpassword">
                <li className="side-nav-sublink">Password</li>
              </Link>

              {/* <Link to="/resetpin">
                <li className="side-nav-sublink">Pin</li>
              </Link> */}
            </ul>
          </li>

          <li>
            <button
              type="button"
              onClick={() => handleToggle("transfer")}
              className={`flex items-center side-link ${
                openSection === "transfer" ? "open " : ""
              }`}
            >
              <span>
                <li class="bi bi-send side-icon"></li>
              </span>
              <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">
                Transfer
              </span>
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 10 6"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 4 4 4-4"
                />
              </svg>
            </button>

            <ul
              className={`subLink-wrapper ${
                openSection === "transfer" ? "open" : ""
              }`}
            >
              <Link to="/localtransfer">
                <li className="side-nav-sublink">
                  <a href="#">Local</a>
                </li>
              </Link>

              <Link to="/InternationalTransfer">
                <li className="side-nav-sublink">
                  <a href="#">International</a>
                </li>
              </Link>

              <Link to="/mobiledeposit">
                <li className="side-nav-sublink">
                  <a href="#">Mobile Deposit</a>
                </li>
              </Link>

              <Link to="/transactionhistory">
                <li className="side-nav-sublink">
                  <a href="#">History</a>
                </li>
              </Link>
            </ul>
          </li>

          <li>
            <button
              type="button"
              onClick={() => handleToggle("monetary")}
              className={`flex items-center side-link ${
                openSection === "monetary" ? "open " : ""
              }`}
            >
              <span>
                <li class="bi bi-bank2 side-icon"></li>
              </span>
              <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">
                Monetary
              </span>
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 10 6"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 4 4 4-4"
                />
              </svg>
            </button>

            <ul
              className={`subLink-wrapper ${
                openSection === "monetary" ? "open" : ""
              }`}
            >
              <Link to="/deposit">
                <li className="side-nav-sublink">Digital Deposit</li>
              </Link>

              <Link to="/card">
                <li className="side-nav-sublink">Card</li>
              </Link>
            </ul>
          </li>

          <li>
            <button
              type="button"
              onClick={() => handleToggle("loan")}
              className={`flex items-center side-link ${
                openSection === "loan" ? "open " : ""
              }`}
            >
              <span>
                <li class="bi bi-cash-coin side-icon"></li>
              </span>
              <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">
                Loan
              </span>
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 10 6"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 4 4 4-4"
                />
              </svg>
            </button>

            <ul
              className={`subLink-wrapper ${
                openSection === "loan" ? "open" : ""
              }`}
            >
              <Link to="/loan">
                <li className="side-nav-sublink">Application</li>
              </Link>

              <Link to="/loanstatus">
                <li className="side-nav-sublink">Status</li>
              </Link>
            </ul>
          </li>

          <li>
            <button
              type="button"
              onClick={() => handleToggle("account")}
              className={`flex items-center side-link ${
                openSection === "account" ? "open " : ""
              }`}
            >
              <span>
                <li class="bi bi-person side-icon"></li>
              </span>
              <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">
                Account
              </span>
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 10 6"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 4 4 4-4"
                />
              </svg>
            </button>

            <ul
              className={`subLink-wrapper ${
                openSection === "account" ? "open" : ""
              }`}
            >
              <Link to="/profile">
                <li className="side-nav-sublink">Profile</li>
              </Link>
              <Link to="/kyc">
                <li className="side-nav-sublink">KYC</li>
              </Link>
            </ul>
          </li>

          <button
            onClick={handleLogout}
            style={{ color: "red", paddingLeft: "20px" }}
          >
            Logout
          </button>

          {/* <li>
            <button
              type="button"
              onClick={() => handleToggle("support")}
              className={`flex items-center side-link ${
                openSection === "support" ? "open " : ""
              }`}
            >
              <span>
                <li class="bi bi-headset side-icon"></li>
              </span>
              <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">
                Support
              </span>
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 10 6"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 4 4 4-4"
                />
              </svg>
            </button>

            <ul
              className={`subLink-wrapper ${
                openSection === "support" ? "open" : ""
              }`}
            >
              <li className="side-nav-sublink">
                <a href="#">New Ticket</a>
              </li>
              <li className="side-nav-sublink">
                <a href="#">View Ticket</a>
              </li>
            </ul>
          </li> */}
        </ul>
      </aside>

      <div className="p-4 sm:ml-64"></div>
    </>
  );
};

export default Template;
