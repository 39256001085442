import { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { useNavigate } from "react-router";
import Loader from "../components/Loader";
import MessageAlert from "../components/MessageAlert";
import useAuthStore from "../stores/authStore";
import useTransactionStore from "../stores/transactionStore";

const Login = () => {
  const login = useAuthStore((state) => state.login);
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const user = useAuthStore((state) => state.user);
  const transfer = useTransactionStore((state) => state.transfer);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [messageID, setMessageID] = useState("");

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  // -------- Redirect user if logged in -----------------
  useEffect(() => {
    if (isLoggedIn && user.role === "admin") {
      navigate("/allusers");
    } else if (isLoggedIn && user.role === "user") {
      navigate("/dashboard");
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    try {
      const result = await (
        await fetch(`https://api-bank.bitinverse.com/api/v1/login`, {
          method: "POST",

          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify(formData),
        })
      ).json();

      if (result.msg) throw result.msg;

      if (result.profile.role === "admin") {
        login(result.profile);
        navigate("/allusers");
        setIsLoading(false);
      } else if (result?.profile?.status === "pending") {
        navigate("/regerror");
      } else {
        setMessage("Login Successful");
        setMessageID("successAlert");
        setShowMessage(true);
        transfer(result.transactions);
        login(result.profile);
        setIsLoading(false);
        navigate("/dashboard");
      }
    } catch (error) {
      setMessage(error.message ? error.message : error);
      setMessageID("declineAlert");
      setShowMessage(true);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Navbar />

      {showMessage ? <MessageAlert data={{ message, messageID }} /> : ""}

      <div class="p-4 reg-section">
        <div className="p-4 produce-form-wrapper">
          <div className="produce-form">
            <form class="" onSubmit={handleSubmit}>
              <h2>LOGIN</h2>
              <div class="mb-4 mt-8">
                <label
                  for="type"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Email
                </label>
                <input
                  type="email"
                  id="type"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div class="mb-4">
                <label
                  for="price"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Password
                </label>
                <input
                  type="password"
                  id="price"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
              </div>

              <button
                style={{ backgroundColor: "#216206" }}
                type="submit"
                class="text-white w-full  focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                {isLoading ? <Loader /> : "Login"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
