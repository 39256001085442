import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import useTransactionStore from "./transactionStore";

const useAuthStore = create(
  devtools(
    persist(
      (set, get) => ({
        isLoggedIn: false,
        user: null,
        showBalance: true,

        login: (userData) =>
          set((state) => ({
            isLoggedIn: true,
            user: { ...state.user, ...userData },
          })),

        handleShowBalance: (show) => {
          set((state) => ({
            showBalance: show,
          }));
        },

        logout: () => {
          // Clear user state
          set(() => ({
            isLoggedIn: false,
            user: null,
          }));

          // Clear transactions array in useTransactionStore
          useTransactionStore.getState().clearTransactions();
        },
      }),
      { name: "profile" }
    )
  )
);

export default useAuthStore;
