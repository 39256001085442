import coin from "../assets/coin.png";
import clip from "../assets/clip.png";

const DepositSection = () => {
  function handleCopy(e) {
    navigator.clipboard.writeText(e);
  }

  return (
    <>
      <div class="p-4 sm:ml-64 content-section">
        <div className="p-4 produce-form-wrapper">
          <div className="produce-form">
            <form>
              <div className="text-center">
                <h3 className="mb-8 dashboard-label">Crypto Deposit</h3>
              </div>
              <div className="mb-5 text-center">
                <p style={{ color: "gray" }}>
                  Make your deposit to either of the two wallet addresses, then
                  insert the amount you've deposited and the payment ID below.
                </p>
              </div>

              <div className="mb-4">
                <label
                  for="type"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  BTC (Bitcoin) Address:
                </label>

                <div className="cryptoWrapper">
                  <input
                    type="readOnly"
                    value="bc1q599vapzsdnf48qykmlcx083j8zrtgscnvh0r3u"
                    className="cryptoAddress text-sm p-2.5 w-full"
                  />

                  <button type="button" className="copyBtn">
                    <img src={clip} alt="copy" />
                  </button>
                </div>
              </div>

              <div className="mb-4">
                <label
                  for="type"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Ethereum (ETH) Address:
                </label>

                <div className="cryptoWrapper">
                  <input
                    type="readOnly"
                    value="0x8C012eB1ED57e5E521460924746424BeA10e3a9A"
                    className="cryptoAddress text-sm p-2.5 w-full"
                  />

                  <button type="button" className="copyBtn">
                    <img src={clip} alt="copy" />
                  </button>
                </div>
              </div>

              <div class="mb-4">
                <label
                  for="type"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Amount
                </label>
                <input
                  type="number"
                  id="type"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  // placeholder="Enter the of crop"
                  name="type"
                  // value={formData.type}
                  // onChange={handleChange}
                  required
                />
              </div>

              <div class="mb-4">
                <label
                  for="price"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Transaction ID
                </label>
                <input
                  type="text"
                  id="price"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  // placeholder="Enter the price"
                  name="price"
                  // value={formData.price}
                  // onChange={handleChange}
                  required
                />
              </div>

              <button
                style={{ backgroundColor: "#216206" }}
                type="submit"
                class="text-white w-full  focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                {/* {isLoading ? <Loader /> : "Submit"} */}
                Deposit
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default DepositSection;
