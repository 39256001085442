import Navbar from "../components/Navbar";
import globalImg from "../assets/global.jpg"

const HomeLoan = () => {
  return (
    <>
      <Navbar />

      <div class="p-4 reg-section">
        <div className="p-4 produce-form-wrapper">
          <div className="produce-form">
            <img src={globalImg} alt="global" />
            {/* <h2>SMALL BUSINESS LOAN INSURANCE</h2> */}
            <h3 className=" mb-2 mt-8 dashboard-label font-bold">
              Small Business Banking Packages
            </h3>
            <p>
              Whether you are an entrepreneur with a great idea just starting
              out, or have been running a small business for years; our goal is
              to make sure you have the support you need to survive, succeed and
              grow. There are many challenges to face when running a small
              business, but managing your everyday banking needs shouldn’t be
              one of them.
            </p>

            <p>
              PBS Business Banking offers you all the usual things you’d expect
              from any bank – a business debit card, cheque book, online banking
              and a range of savings and borrowing services – nonetheless,
              you’ll also find we offer quite a bit more; like a Small Business
              Banker who is dedicated to supporting you and your business.
            </p>

            <p>
              We can also provide you with access to networking events, best
              practice seminars, award winning business management software,
              business skills, training courses and much more
            </p>

            <p>
              For loans under $100,000, you can apply at Engineering Funds and
              your protection could begin right away. Coverage is also available
              on loans up to $500,000.
            </p>

            <h3 className=" mb-2 mt-8 dashboard-label font-bold">
              Professional and Career Development Loan
            </h3>

            <p>
              A Professional and Career Development Loan from The Engineering
              Funds Bank helps to finance your studies and improve your career
              prospects.
            </p>

            <p>
              Borrow between $300 and $10,000 helping you fund a course to
              improve your career prospects and earning potential
            </p>

            <p>The government pays the interest during your studies</p>

            <p>
              Repay your Professional and Career Development Loan over 1 to 5
              years, only paying it back after you’ve completed your course
            </p>

            <p>
              Enjoy fixed repayments on your Professional and Career Development
              Loan
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeLoan;
