import logo from "../assets/logo.png";


const Footer = () => {
  return (
    <div className="footer">
      <img src={logo} alt="lgo" id="big-logo" />

      <p>&copy; 1994 - 2024 First Monument. All rights reserved</p>
    </div>
  );
};

export default Footer;
