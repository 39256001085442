import { useLocation, useNavigate } from "react-router";
import AdminTemplate from "../components/AdminTemplate";
import { useEffect, useState } from "react";
import Loader from "../components/Loader";
import MessageAlert from "../components/MessageAlert";

const ManageUser = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [istransfering, setIsTransfering] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [messageID, setMessageID] = useState("");
  const user = location.state?.aUser;
  const [newBalance, setNewBalance] = useState(user?.totalBalance);
  const [newStatus, setNewStatus] = useState(user?.status);

  const [formData, setFormData] = useState({
    amount: null,
    accountNumber: "",
    accountHolder: "",
    type: "",
    email: user?.email,
    details: "",
  });

  const [updateData, setUpdateData] = useState({
    email: user?.email,
    totalBalance: null,
    status: "",
  });

  useEffect(() => {
    if (!user) return navigate("/allusers");
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleGrabData = (e) => {
    const { name, value } = e.target;
    setUpdateData({
      ...formData,
      [name]: value,
    });
  };

  // ------------- Transfer -------------------------
  const handleTransfer = async (e) => {
    e.preventDefault();
    setIsTransfering(true);

    try {
      const result = await (
        await fetch(`https://api-bank.bitinverse.com/api/v1/transfer`, {
          method: "POST",

          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify(formData),
        })
      ).json();

      setNewBalance(result?.user?.totalBalance);

      setMessage("Transaction Successful!");
      setMessageID("successAlert");
      setShowMessage(true);
      setIsTransfering(false);
      setFormData({
        amount: "",
        accountNumber: "",
        accountHolder: "",
        type: "",
        email: user?.email,
        details: "",
      });
    } catch (error) {
      console.log(error);
    }
  };

  // ------------- Update Profile -------------------------
  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const result = await (
        await fetch(`https://api-bank.bitinverse.com/api/v1/updateprofile`, {
          method: "POST",

          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify(updateData),
        })
      ).json();

      setNewBalance(result.totalBalance);
      setNewStatus(result.status);

      setMessage("Profile updated successfully");
      setMessageID("successAlert");
      setShowMessage(true);
      setIsLoading(false);
      setUpdateData({
        email: user?.email,
        totalBalance: "",
        status: "",
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <AdminTemplate />

      {showMessage ? (
        <MessageAlert
          data={{ message, messageID }}
          onDurationChange={setTimeout(() => {
            setShowMessage(false);
          }, 2000)}
        />
      ) : (
        ""
      )}

      <div class="p-4 sm:ml-64 content-section ">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div class="bg-gray-200 p-4">
            <form onSubmit={handleTransfer}>
              <h3 className="mb-8 dashboard-label">Balance Update</h3>

              <div class="mb-4">
                <label
                  for="type"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Currency
                </label>
                <select
                  className=" w-full peer h-full shadow-sm produce-input block p-2.5"
                  required
                  name={"cardCompany"}
                  //   value={formData.cardCompany}
                  //   onChange={handleChange}
                >
                  <option value="masterCard">US Dollars</option>
                </select>
              </div>

              <div class="mb-4">
                <label
                  for="type"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Amount ($)
                </label>
                <input
                  type="number"
                  id="type"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  name="amount"
                  value={formData.amount}
                  onChange={handleChange}
                  required
                />
              </div>

              <div class="mb-4">
                <label
                  for="type"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Account Number
                </label>
                <input
                  type="text"
                  id="type"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  name="accountNumber"
                  value={formData.accountNumber}
                  onChange={handleChange}
                  required
                />
              </div>

              <div class="mb-4">
                <label
                  for="type"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Account Holder
                </label>
                <input
                  type="text"
                  id="type"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  name="accountHolder"
                  value={formData.accountHolder}
                  onChange={handleChange}
                  required
                />
              </div>

              <div class="flex items-center mb-4">
                <input
                  id="credit"
                  type="radio"
                  name="type"
                  checked={formData.type === "credit"}
                  value="credit"
                  onChange={handleChange}
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  for="credit"
                  class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Credit
                </label>
              </div>

              <div class="flex items-center">
                <input
                  id="debit"
                  type="radio"
                  checked={formData.type === "debit"}
                  value="debit"
                  name="type"
                  onChange={handleChange}
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  for="debit"
                  class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Debit
                </label>
              </div>

              <div className="mb-4 mt-4">
                <label
                  for="availability"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Details
                </label>
                <textarea
                  class="peer h-full shadow-sm produce-input block w-full p-2.5  "
                  name="details"
                  value={formData.details}
                  onChange={handleChange}
                ></textarea>
              </div>

              <button
                style={{ backgroundColor: "#216206" }}
                type="submit"
                class="text-white w-full  focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                {istransfering ? <Loader /> : "Update Balance"}
              </button>
            </form>
          </div>

          {/* -------------- col 2 ----------------------------- */}
          <div class="bg-gray-200 p-4">
            <h3 className="mb-8 mt-3 dashboard-label">User Data</h3>
            <table class="items-center  w-full border-collapse ">
              <tbody>
                <tr>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-3 text-left text-blueGray-700 ">
                    Name:
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-3 ">
                    {`${user.firstName} ${user.secondName}`}
                  </td>
                </tr>
                <tr>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-3 text-left text-blueGray-700 ">
                    Account Number:
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-3 ">
                    {user.accountNumber}
                  </td>
                </tr>
                <tr>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-3 text-left text-blueGray-700 ">
                    Account Type:
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-3 ">
                    {user.accountType}
                  </td>
                </tr>
                <tr>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-3 text-left text-blueGray-700 ">
                    Status:
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-3 ">
                    <span
                      className="badge"
                      id={`${newStatus === "blocked" ? "danger" : "approved"}`}
                    >
                      {newStatus}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-3 text-left text-blueGray-700 ">
                    USD Balance:
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-3 ">
                    ${newBalance}
                  </td>
                </tr>
              </tbody>
            </table>

            <form onSubmit={handleUpdateProfile}>
              <div class="mb-4 mt-4">
                <label
                  for="type"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Total Balance
                </label>
                <input
                  type="number"
                  id="type"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  name="totalBalance"
                  value={updateData.totalBalance}
                  onChange={handleGrabData}
                />
              </div>

              <div class="mb-4">
                <label
                  for="type"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Status
                </label>
                <select
                  className=" w-full peer h-full shadow-sm produce-input block p-2.5"
                  required
                  name={"status"}
                  value={updateData.status}
                  onChange={handleGrabData}
                >
                  <option value="active">-- select --</option>
                  <option value="active">Active</option>
                  <option value="blocked">Blocked</option>
                </select>
              </div>

              <button
                style={{ backgroundColor: "#216206" }}
                type="submit"
                class="text-white w-full mt-8 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                {isLoading ? <Loader /> : " Update Profile"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageUser;
