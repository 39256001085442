import { Link, useNavigate } from "react-router-dom";

const Banner = () => {
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate("/login");
  };

  const handleSignup = () => {
    navigate("/signup");
  };

  return (
    <>
      <div className="hero">
        <div className="heroBtnWrapper">
          <Link to="/signup">
            <button className="signUp">Register</button>
          </Link>

          <Link to="/login">
            <button className="login">Login</button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Banner;
