import { useEffect, useState } from "react";
import avatar from "../assets/profile.png";
import useAuthStore from "../stores/authStore";
import { useNavigate } from "react-router";
import man from "../assets/man.jpg";
import man2 from "../assets/man2.png";
import linImg from "../assets/lin.jpg";
import kayla from "../assets/kayla";
import woman from "../assets/woman.png";
import person from "../assets/person.jpg"

const ProfileSection = () => {
  const navigate = useNavigate();
  const user = useAuthStore((state) => state.user);
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);

  const [profileImg, setProfileImg] = useState(user?.profilePicture);
  const [imgUpdated, setImgUpdated] = useState(false);
  const [imgSize, setImgSize] = useState(0);

  // -------- Redirect user if logged in -----------------
  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, []);

  const capitalizeFirstLetter = (string) => {
    if (string) {
      return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
    }
  };

  // -------------- Grab image an set the profile image ----------------------
  const handleAddProfile = (e) => {
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);

    setImgSize(e.target.files[0].size);

    reader.onloadend = () => {
      setProfileImg(reader.result);
      setImgUpdated(true);
    };
  };

  // console.log(typeof user.firstName)

  // function arrayBufferToBase64(buffer) {
  //   let binary = "";
  //   const bytes = new Uint8Array(buffer);
  //   const len = bytes.byteLength;
  //   for (let i = 0; i < len; i++) {
  //     binary += String.fromCharCode(bytes[i]);
  //   }
  //   return btoa(binary);
  // }
  // const base64Img = arrayBufferToBase64(user?.profilePicture?.data);

  // console.log(`url("data:image/jpeg;base64, ${base64Img}")`);

  return (
    <>
      <div class="p-4 sm:ml-64 content-section">
        <div className="p-4 produce-form-wrapper">
          <div className="produce-form">
            <form>
              <div className="avatarWrapper flex justify-center">
                <div
                  className="profileBox"
                  style={{
                    backgroundImage: `url(${
                      user.firstName === "fazza "
                        ? man
                        : user.firstName === "melissa"
                        ? woman
                        : user.firstName === "paul"
                        ? person
                        : avatar
                    })`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  <input
                    type="file"
                    id="profileImage"
                    accept=".jpg, .jpeg, .png"
                    onChange={(e) => handleAddProfile(e)}
                    hidden
                  />
                  <label htmlFor="profileImage" className="avatarInputIcon">
                    <i className="b bi-pencil "></i>
                  </label>
                </div>
              </div>

              {/* ------------ Bio -------------- */}
              <h3 className="mb-4 mt-8 dashboard-label">Bio</h3>
              <table class="items-center  w-full border-collapse border border-slate-500">
                <tr>
                  <td class="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700 ">
                    First Name:
                  </td>
                  <td class="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 ">
                    {capitalizeFirstLetter(user?.firstName)}
                  </td>
                </tr>

                <tr>
                  <td class="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700 ">
                    Second Name:
                  </td>
                  <td class="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 ">
                    {capitalizeFirstLetter(user?.secondName)}
                  </td>
                </tr>

                <tr>
                  <td class="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700 ">
                    Birthdate:
                  </td>
                  <td class="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 ">
                    {capitalizeFirstLetter(user?.dob)}
                  </td>
                </tr>

                <tr>
                  <td class="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700 ">
                    Gender:
                  </td>
                  <td class="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 ">
                    Female
                  </td>
                </tr>

                <tr>
                  <td class="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700 ">
                    Email:
                  </td>
                  <td class="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 ">
                    {user?.email}
                  </td>
                </tr>
              </table>

              {/* ------------ Address -------------- */}
              <h3 className="mb-4 mt-8 dashboard-label">Address</h3>
              <table class="items-center  w-full border-collapse border border-slate-500">
                <tr>
                  <td class="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700 ">
                    Country:
                  </td>
                  <td class="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 ">
                    {capitalizeFirstLetter(user?.country)}
                  </td>
                </tr>

                <tr>
                  <td class="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700 ">
                    State:
                  </td>
                  <td class="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 ">
                    {capitalizeFirstLetter(user?.state)}
                  </td>
                </tr>

                <tr>
                  <td class="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700 ">
                    City:
                  </td>
                  <td class="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 ">
                    {capitalizeFirstLetter(user?.city)}
                  </td>
                </tr>

                <tr>
                  <td class="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700 ">
                    Zipcode:
                  </td>
                  <td class="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 ">
                    {user?.zipCode}
                  </td>
                </tr>
              </table>

              {/* ------------ Next of kin -------------- */}
              {/* <h3 className="mb-4 mt-8 dashboard-label">Next of Kin</h3>
              <table class="items-center  w-full border-collapse border border-slate-500">
                <tr>
                  <td class="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700 ">
                    First Name:
                  </td>
                  <td class="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 ">
                    John
                  </td>
                </tr>

                <tr>
                  <td class="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700 ">
                    Last Name:
                  </td>
                  <td class="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 ">
                    Doe
                  </td>
                </tr>

                <tr>
                  <td class="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700 ">
                    Relationship:
                  </td>
                  <td class="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 ">
                    05 May 1997
                  </td>
                </tr>

                <tr>
                  <td class="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700 ">
                    Address:
                  </td>
                  <td class="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 ">
                    Female
                  </td>
                </tr>
              </table> */}

              {/* ------------ System -------------- */}
              <h3 className="mb-4 mt-8 dashboard-label">System</h3>
              <table class="items-center  w-full border-collapse border border-slate-500">
                <tr>
                  <td class="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700 ">
                    Currency:
                  </td>
                  <td class="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 ">
                    USD
                  </td>
                </tr>

                <tr>
                  <td class="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700 ">
                    Account:
                  </td>
                  <td class="border border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 ">
                    {capitalizeFirstLetter(user?.accountType)}
                  </td>
                </tr>
              </table>

              <button
                style={{ backgroundColor: "#216206" }}
                type="submit"
                class="mt-8 text-white w-full  focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                {/* {isLoading ? <Loader /> : "Submit"} */}
                Upate
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileSection;
