import Navbar from "../components/Navbar";

const RegError = () => {
  return (
    <>
      <Navbar />
      <div class="p-4 reg-section">
        <div className="p-4 produce-form-wrapper">
          <div className="produce-form text-center">
            <h2>Rewiew Process</h2>
            <p className="mt-8">
              Your online banking account with First Monument is currently under
              review, our online banking team will review your application, you
              will be notified once the account is active.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegError;
