import DepositSection from "../components/DepositSection";
import Template from "../components/Template";

const Deposit = () => {
  return (
    <>
      <Template />
      <DepositSection />
    </>
  );
};

export default Deposit;
