const KYCSection = () => {
  return (
    <>
      <div class="p-4 sm:ml-64 content-section">
        <div className="p-4 produce-form-wrapper">
          <div className="produce-form">
            <h3 className=" mb-4 dashboard-label">KYC Document</h3>

            <div className="cheque-input">
              <p style={{ color: "gray" }}>
                Please Click the button below to upload KYC document
              </p>
              <input type="file" id="upload-cheque" hidden />
              <label htmlFor="upload-cheque" className="upload-cheque-btn">
                {" "}
                Upload document
              </label>
            </div>

            <div style={{ textAlign: "center" }} className="mt-8">
              <button className="upload-cheque-btn" id="deposit-cheque-btn">
                Submit Upload
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default KYCSection;
